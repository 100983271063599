<template>
    <seccion-datos :titulo="especialidades" :botonGuardar="false">
        <div class="form-group">
            <label>{{ $t('general.especialidad') }}</label>
            <select class="custom-select form-control select2 especialidades">
                <option value="">{{ $t('general.seleccioneespecialidad') }}</option>
                <option v-for="esp in todasEspecialidades" :key="idCia + '_' + esp.id" :data-id="'esp-cia-' + idCia + '_' + esp.id" :value="esp.id">{{ esp.nombre }}</option>
            </select>
        </div>
        <div class="row" v-if="datos && datos.especialidades">
            <div class="col-xs-12">
                <table class="table table-striped table-responsive">
                    <thead>
                        <tr>
                            <th>{{ $t('general.especialidad') }}</th>
                            <th>{{ $t('general.diasdecaducidad')}}</th>
                            <th>{{ $t('general.diasdecaducidadporconfirmar')}}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="especialidadCia in datos.especialidades" :key="'esp-asig-cia-' + idCia + '_' + especialidadCia.idespecialidad">
                            <td>{{ especialidadCia.nombre }}</td>
                            <td><input type="number" class="control form-control" v-model="especialidadCia.caducidad_especialidad"></td>
                            <td><input type="number" class="control form-control" v-model="especialidadCia.caducidad_confirmar_especialidad"></td>
                            <td> <button type="button" class="btn btn-block btn-outline-secondary btn-xs" @click="guardar(especialidadCia)">{{ $t('general.guardar') }}</button>
                                <button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(especialidadCia.especialidades_idespecialidad)">{{ $t('general.eliminar') }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import $ from 'jquery'
export default {
    props: {
        datos: {
            type: Object
        }
    },
    inject: ['agregarEspecialidad', 'eliminarEspecialidad', 'actualizarEspecialidad'],
    data() {
        return {
            todasEspecialidades: [],
            especialidades:'Especialidades'
        }
    },
    computed: {
        idCia() {
            return this.datos?.id || 0; 
        }
    },
    methods: {
        agregar(idEspecialidad) {
            this.agregarEspecialidad(idEspecialidad);
        },
        eliminar(idEspecialidad) {
            this.eliminarEspecialidad(idEspecialidad);
        },
        guardar(especialidadCia) {
            this.actualizarEspecialidad(especialidadCia.especialidades_idespecialidad, {
                caducidad_especialidad: especialidadCia.caducidad_especialidad,
                caducidad_confirmar_especialidad: especialidadCia.caducidad_confirmar_especialidad,
            });
        }
    },
    created() {
        this.$store.dispatch('especialidades/obtenerEspecialidades');
        this.todasEspecialidades = this.$store.getters['especialidades/especialidades'];
    },
    mounted() {
        const $select = $('.select2.especialidades');
        $select.select2({
            theme: 'bootstrap4'
        })
        $select.off('select2:select').on('select2:select', (e) => {
            var data = e.params.data;
            this.agregar(data.id);
        });
        this.especialidades = this.$t('general.especialidades')
    }
}
</script>