<template>
    <div class="row">
        <div class="col-md-8">
            <!-- Inicio Tipo sistema -->
            <compania-especialidades-especialidades
                :datos="datos" 
            />
        </div>
    </div> 
</template>

<script>
import CompaniaEspecialidadesEspecialidades from './secciones/CompaniaEspecialidadesEspecialidades.vue'

export default {
    components: {
        CompaniaEspecialidadesEspecialidades,
    },
    props: {
        datos: {
            type: Object
        },
    }
}
</script>

